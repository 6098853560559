import MetaTags from '@components/MetaTags/MetaTags'
import ToastifyContainer from '@components/ToastifyContainer/ToastifyContainer'
import ScrollToTop from '@helpers/scrollToTop'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import * as Sentry from '@sentry/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import dayjs from 'dayjs'
import 'dayjs/locale/de'
import 'dayjs/locale/en'
import updateLocale from 'dayjs/plugin/updateLocale'
import { useEffect } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { BrowserRouter } from 'react-router-dom'

import theme from './assets/themes/theme'
import FeatureToggleProvider from './context/FeatureToggleProvider'
import './translation/config'
import { AppContainer } from './ui/wrappers/AppContainer/AppContainer'

dayjs.extend(updateLocale)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: 'always',
      retry: 1,
      staleTime: 6 * 60 * 1000,
    },
  },
})

const App = () => {
  const { i18n } = useTranslation()

  useEffect(() => {
    dayjs.locale(i18n.language)
    dayjs.updateLocale(i18n.language, {
      weekStart: 1,
    })
  }, [i18n.language])

  return (
    <FeatureToggleProvider>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language}>
            <CssBaseline />
            <BrowserRouter
              future={{
                v7_startTransition: true,
                v7_relativeSplatPath: true,
              }}
            >
              <HelmetProvider>
                <MetaTags />
                <ScrollToTop />
                <AppContainer />
                <ToastifyContainer />
              </HelmetProvider>
            </BrowserRouter>
          </LocalizationProvider>
        </ThemeProvider>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-left" />
      </QueryClientProvider>
    </FeatureToggleProvider>
  )
}

export default Sentry.withProfiler(App)
